import { mainLanding } from "./landing.js";

const detect = navigator.userAgent;

if (detect.indexOf('iPhone') > 0 && detect.indexOf('CPU iPhone') > 0 && detect.indexOf('IOS iPhone') && detect.indexOf('Mobile')) {
  localStorage.setItem("userDevice", JSON.stringify({ device: "iPhone"}));

} else if (detect.indexOf('Android') > 0 && detect.indexOf('Chrome') > 0 && detect.indexOf('Mobile') > 0) {

  localStorage.setItem("userDevice", JSON.stringify({ device: "Android"}));

} else if (detect.indexOf('iPad') > 0 && detect.indexOf('CPU OS') > 0 && detect.indexOf('Mobile')) {

  localStorage.setItem("userDevice", JSON.stringify({ device: "iPad"}));

} else if (detect.indexOf('Safari') > 0 && detect.indexOf('Macintosh') > 0) {

  localStorage.setItem("userDevice", JSON.stringify({ device: "Safari Desktop"}));
} else {

  localStorage.setItem("userDevice", JSON.stringify({ device: "Desktop"}));
}


fetch('assets/content/pages/landing.html')
.then(result => result.text())
.then(html => {

    document.body.insertAdjacentHTML("beforeEnd",html);

    mainLanding()
})